import React, { Component } from "react";
import "./MenuList.css";
import Two from "../../images/menus/menu-natomas.jpeg";
import Happy from "../../images/menus/natomas-happy2.jpeg";
import Three from "../../images/menus/bar-natomas.jpeg";
import M from "materialize-css";

export default class MenuList2 extends Component {
  componentDidMount() {
    M.Tabs.init(this.Tabs, {
      swipeable: false,
    });
  }

  render() {
    return (
      <div className="menu-list">
        {/* <ReactTooltip type="dark" effect="solid" /> */}
        <div id="index-banner" className="header-menu">
          <div className="header-title">
            <br />
            <h1 className="center">Menu</h1>
          </div>
        </div>

        <div className="container next">
          <div className="row">
            <ul
              ref={(Tabs) => {
                this.Tabs = Tabs;
              }}
              className="tabs"
            >
              <li className="tab col s4 m4">
                <a
                  data-tip="Entree (Natomas)"
                  className="active"
                  href="#tab__one"
                >
                  Entree (Natomas)
                </a>
              </li>
              <li className="tab col s4 m4">
                <a data-tip="Happy Hour" href="#tab__three">
                  Happy Hour
                </a>
              </li>
              <li className="tab col s4 m4">
                <a data-tip="Drinks" href="#tab__four">
                  Drinks
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div id="menu">
          <div className="menu-tabs">
            <div
              style={{ display: "flex !important", justifyContent: "center" }}
              id="tab__one"
              className="col s12 flex"
            >
              <img
                width="auto"
                height="auto"
                className="responsive-img"
                src={Two}
                alt="menu"
              />
            </div>

            <div
              style={{ display: "flex !important", justifyContent: "center" }}
              id="tab__three"
              className="col s12 flex"
            >
              <img
                width="auto"
                height="auto"
                className="responsive-img"
                src={Happy}
                alt="happy hour"
              />
            </div>

            <div
              style={{ display: "flex !important", justifyContent: "center" }}
              id="tab__four"
              className="col s12 flex"
            >
              <img
                width="auto"
                height="auto"
                className="responsive-img"
                src={Three}
                alt="cocktails"
              />
            </div>
          </div>
        </div>

        <div className="next-section"></div>
      </div>
    );
  }
}
